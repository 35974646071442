import React from 'react'
import { MediaItem, Link } from '../../../../plugins/gatsby-plugin-atollon'
import useInspectionProperties from "../../../hooks/use-inspection-properties"
import { graphql } from 'gatsby'
import AddToCalendar from '@culturehq/add-to-calendar'
import moment from 'moment'

const UpcomingInspections = ({ title }) => {

  const properties = useInspectionProperties()
  //endDateTimeUtc
  //startDateTimeUtc

  const nextInspection = (page) => {
    return page.inspections.map((el, i) => {
      if (moment(el.startDateTimeUtc).add(0, 'days').isBefore(new Date())) return false
      return el
    }).filter(el => el)[0]
  }

  properties.sort((a, b) => {
    const ai = nextInspection(a)
    const ab = nextInspection(b)
    if (ai && ab) {
      return moment(ai.startDateTimeUtc).diff(moment(ab.startDateTimeUtc)) > 0 ? 1 : -1
    }
    if (!ab) {
      return -1
    }
    if (!ai) {
      return 1
    }
  })

  const renderInspections = (page) => {

    page.inspections?.length > 0 && page.inspections.sort((a, b) => {
      return moment(a.startDateTimeUtc).diff(moment(b.startDateTimeUtc)) > 0 ? 1 : -1
    })

    // filter out inspections that have same startDateTimeUtc
    let inspections = page.inspections?.length > 0 && page.inspections.filter((el, i) => {
      return page.inspections.map(el => el.startDateTimeUtc).indexOf(el.startDateTimeUtc) === i
    })

    return inspections?.length > 0 && inspections?.map((el, i) => {
      if (moment(el.startDateTimeUtc).add(0, 'days').isBefore(new Date())) return false
      const event = {
        name: page.title,
        details: 'Open For Inspection',
        location: page.title,
        startsAt: moment(el.startDateTimeUtc),
        endsAt: moment(el.endDateTimeUtc),
      }
      return (
        <AddToCalendar event={event} key={i}>
          <span className='date-first'>{moment(el.startDateTimeUtc).format('ddd D MMM')}</span><span className='date-sep'>—</span> {moment(el.startDateTimeUtc).format('h:mm A')} - {moment(el.endDateTimeUtc).format('h:mm A')}
        </AddToCalendar>
      )
    }).filter(el => el)
  }

  return (
    <>
      <section className='upcoming'>
        <div className='upcoming__header'>
          <div className='upcoming__inner'>
            <h1>{ title }</h1>
          </div>
        </div>
        <div className='upcoming__body'>
          <div className='upcoming__inner'>
            <div className='upcoming__items'>
              { properties?.map((el, i) => {
                const inspections = renderInspections(el)
                const { address, images, permalink } = el

                if (!inspections || inspections?.length === 0) return null

                return (
                  <div className='upcoming__item' key={i}>
                    <div className='upcoming__image'>
                      <Link to={permalink}>
                        {images.length > 0 && <MediaItem item={images[0].primary[0]} />}
                      </Link>
                    </div>
                    <h4 className='upcoming__title'>
                      <Link to={permalink}>{address}</Link>
                    </h4>
                    <div className='upcoming__meta'>
                      <p>Inspections</p>
                      { inspections }
                    </div>
                  </div>
                )
              }) }
            </div>
            { properties?.length === 0 && <p className='upcoming__no-results'>No listings at this time.</p> }
          </div>
        </div>
      </section>
    </>
  )
}

export default UpcomingInspections

export const query = graphql`
fragment UpcomingInspectionsProps on DatoCmsUpcomingInspection {
  ... on DatoCmsUpcomingInspection {
    title
  }
}
`