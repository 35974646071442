import React, { Component } from 'react'

import { Logo, Icon, Arrow, LongArrow } from '../../icons'
import { Link, Asset } from '../../../../plugins/gatsby-plugin-atollon'
import FormComponent from '../../form';
import { graphql } from 'gatsby'
import StructuredText from '../custom-structured-text-renderer'
import parse from 'html-react-parser'


class Office extends Component {

  state = {
    showContact: false,
    hovered: false,
    formTitle: 'Woollahra',
    formEmail: 'woollahra@trg.com.au',
  }

  openForm = (formTitle, formEmail) => {
    this.setState({
      showContact: true,
      formTitle: formTitle,
      formEmail: formEmail,
    })
  }

  render() {
    let { items, form } = this.props
    let { showContact, formTitle, formEmail, hovered } = this.state

    return (
      <>
        <section className='offices'>
          <div className='offices__inner'>
            { items?.length > 0 &&
              <div className='offices__items'>
                { items.map((el, i) => (
                  <div className={ `offices__item ${ hovered !== i && hovered !== false ? 'offices__item--blur' : '' }` } key={ i }>
                    { el.image &&
                      <div className='offices__image'>
                        <Asset asset={ el.image } alt={ el.title }/>
                      </div>
                    }
                    <h3 className='offices__title'><Icon color={ `#21242F` }/> { el.title }</h3>
                    <ul className='offices__contact'>
                      <li>
                        <h4>Location</h4>
                        { el.richAddress && <StructuredText data={ el.richAddress }/> }
                      </li>
                      { el.phone[0]?.title &&
                        <li>
                          <h4>Phone</h4>
                          { el.phone?.length > 0 &&
                            <Link className='image-cta__button' to={ el.phone[0].link[0].externalLink ? el.phone[0].link[0].externalLink : el.phone[0].link[0].internalLink.permalink }>{ parse(
                              el.phone[0].title) }</Link> }
                        </li>
                      }
                      <li>
                        <h4>&nbsp;</h4>
                        <button
                          type='button'
                          onMouseEnter={ () => this.setState({ hovered: i }) }
                          onMouseLeave={ () => this.setState({ hovered: false }) }
                          style={ { fontWeight: hovered === i ? '600' : '400' } }
                          onClick={ () => this.openForm(el.title, el.email.text) }>
                          Enquire Online { hovered === i ? <LongArrow color={ `#21242F` }/> : <Arrow color={ `#21242F` }/> }
                        </button>
                      </li>
                    </ul>
                  </div>
                )) }
              </div>
            }
          </div>
        </section>
        <section className={ `contact-form ${ showContact ? 'active' : '' }` }>
          <div className='contact-form__header'>
            <div className='contact-form__outer'>
              <div>
                <Icon color={ `#FFFFFF` }/>
                <Logo color={ `#FFFFFF` }/>
              </div>
              <button type='button' onClick={ () => this.setState({ showContact: !showContact }) } className='contact-form__close'>Close</button>
            </div>
          </div>
          <div className='contact-form__main'>
            <div className='contact-form__inner'>
              <div className='contact-form__content'>
                <p>TRG is synonymous with Sydney’s elite property. A real estate agency built on a commitment to challenge the traditional and continually deliver for our clients.</p>
                <p>Please fill out a few simple details so we can assist with your enquiry. We will be in touch as soon as possible.</p>
              </div>
              <div className='contact-form__form'>
                { formTitle === 'Woollahra' && <FormComponent form={form[0].form} /> }
                { formTitle === 'Rose Bay' && <FormComponent form={form[1].form} /> }
              </div>
            </div>
          </div>
          <div className='contact-form__footer'>
            <div className='contact-form__outer'>
              <div className='contact-form__footer-title'>
                <h3>{ formTitle }</h3>
              </div>
              <span>Office</span>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Office

export const query = graphql`
fragment OfficeProps on DatoCmsOffice {
  ... on DatoCmsOffice {
    id
    items {
      image {
        ...InternalAssetProps
      }
      richAddress {
        value
      }
      title
      email {
        ...LinkWithTitleProps
      }
      phone {
        ...LinkWithTitleProps
      }
    }
    form {
      form {
        ...FormProps
      }
    }
  }
}
`
