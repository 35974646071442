import React, { Component } from 'react'
import { Link } from "../../plugins/gatsby-plugin-atollon"

import { HouseLogo } from './icons'
import NewsletterForm from './newsletter-form'
import StructuredText from './structured-text/custom-structured-text-renderer'

class Footer extends Component {

  state = {
    officeHover: false
  }

  getOfficeProps = (i) => {
    let oh = this.state.officeHover

    return {
      key: i,
      className: `${typeof oh !== 'number' || oh === i ? '' : 'in'}active`,
      onMouseEnter: () => this.setState({ officeHover: i }),
      //onMouseLeave: () => this.setState({ officeHover: false }),
    }
  }

  render() {
    const offices = this.props.offices
    let { officeHover } = this.state

    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__top'>
            <div className='footer__logos'>
              <Link to='/' title='Site Name' className='footer__logo' onClick={this.props.toggleTheme}>
                <HouseLogo />
              </Link>
              <ul>
                { offices?.map((el, i) => (
                  <li {...this.getOfficeProps(i)}><span>— { el.title }</span></li>
                )) }
              </ul>

              { officeHover !== false &&
                <p>
                  <Link to={`https://www.google.com/maps/search/`}><StructuredText data={ offices[officeHover].richAddress } /></Link>
                  <Link to={`tel:${offices[officeHover].phone[0].link[0].externalLink}`}>{ offices[officeHover].phone[0].title }</Link>
                </p>
              }
            </div>
            <div className='footer__newsletter'>
              <p>Never miss a piece of the action. Subscribe for new listings, sales results, podcast releases, and media exclusives from TRG</p>
              <NewsletterForm />
            </div>
          </div>
          <div className='footer__bottom'>
            <div className='footer__copyright'>
              <p><span className='copy'>©</span> The Rubinstein Group {new Date().getFullYear()}</p>
            </div>
            <div className='footer__social'>
              <ul>
                <li><Link to='https://www.instagram.com/trg.re/'>Instagram</Link></li>
                <li><Link to='https://www.linkedin.com/company/thrubinsteingroup/'>LinkedIn</Link></li>
              </ul>
            </div>
            <div className='footer__links'>
              <ul>
                <li><Link to='/privacy-policy/'>Privacy</Link></li>
                <li><Link to='/terms-conditions/'>Terms &amp; Conditions</Link></li>
              </ul>
              <ul>
                <li className='copy'><span className='copy'>©</span> The Rubinstein Group {new Date().getFullYear()}</li>
                <li>Design by <Link to='https://atollon.com.au'>Atollon</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
